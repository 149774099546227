import { Contexts } from './enums/Contexts';
import { Permissions } from './enums/Permissions';
import { ROLES_BY_CONTEXT_AND_PERMISSION } from './RolesByContextAndPermission';
import { AuthContext } from './types/AuthContext';
import { PermissionsByContext } from './types/PermissionsByContext';

const PERMISSIONS_PAIR = Object.entries(Permissions);

const CONTEXTS = Object.keys(Contexts) as Array<keyof typeof Contexts>;

function buildPermissions(context: Contexts): PermissionsByContext {
  return PERMISSIONS_PAIR.reduce(
    (permissions: PermissionsByContext, [key, value]) => {
      return {
        ...permissions,
        [key]: [context, value],
      };
    },
    {} as PermissionsByContext
  );
}

function buildAuthContext(): AuthContext {
  return CONTEXTS.reduce((authContext: AuthContext, contextKey) => {
    const context = Contexts[contextKey];

    authContext[contextKey] = {
      context,
      roles: ROLES_BY_CONTEXT_AND_PERMISSION[contextKey],
      permissions: buildPermissions(context),
    };
    return authContext;
  }, {} as AuthContext);
}

export const AUTH_CONTEXT = buildAuthContext();
