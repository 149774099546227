import { Roles } from './enums/Roles';
import { RolesByContextAndPermission } from './types/RolesByContextAndPermission';

const ADMIN_PROMOTION = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
};

const BI = {
  CREATE: [Roles.MARKETING],
  DELETE: [],
  LIST: [Roles.MARKETING],
  READ: [Roles.MARKETING],
  UPDATE: [],
};

const BUNDLE = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.AUTOR,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.AUTOR,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.AUTOR,
  ],
};

const CAMPAIGN = {
  CREATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  UPDATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
};

const CARRIER = {
  CREATE: [Roles.SUPER_ADMIN],
  DELETE: [],
  LIST: [],
  READ: [],
  UPDATE: [],
};

const CLUB = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
};

const CONTRIBUTOR = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
  ],
};

const CORP_ADMIN = {
  CREATE: [],
  DELETE: [],
  LIST: [],
  READ: [Roles.CORP_ADMIN],
  UPDATE: [],
};

const DISTRIBUTOR = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
};

const DOCUMENT_FAQ = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
};

const DOCUMENT_POLICY = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
};

const DOCUMENT_TERM = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.MARKETING,
  ],
};

const DEV_TOOLS = {
  CREATE: [Roles.ADMIN_SKEELO, Roles.SUPER_ADMIN],
  DELETE: [Roles.ADMIN_SKEELO, Roles.SUPER_ADMIN],
  LIST: [Roles.ADMIN_SKEELO, Roles.SUPER_ADMIN],
  READ: [Roles.ADMIN_SKEELO, Roles.SUPER_ADMIN],
  UPDATE: [Roles.ADMIN_SKEELO, Roles.SUPER_ADMIN],
};

const IMPRINT = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
  ],
  DELETE: [Roles.SUPER_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
};

const LINKED_BOOK = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
  ],
};

const SHORTEN_URL = {
  CREATE: [Roles.MARKETING],
  DELETE: [],
  LIST: [],
  READ: [],
  UPDATE: [],
};

const PLAN = {
  CREATE: [Roles.SUPER_ADMIN],
  DELETE: [Roles.SUPER_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  UPDATE: [Roles.SUPER_ADMIN],
};

const PRICE_RANGE = {
  CREATE: [Roles.SUPER_ADMIN],
  DELETE: [Roles.SUPER_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
  UPDATE: [Roles.SUPER_ADMIN],
};

const PRODUCT = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
};

const PUBLISHER = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
  ],
  DELETE: [Roles.SUPER_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
};

const STORE = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.AUTOR,
  ],
};

const STREAMING = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.AUTOR,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.AUTOR,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.EDITORIAL_SKEELO_INTERMEDIARIO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.AUTOR,
  ],
};

const SUBSCRIBER = {
  CREATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.ATENDIMENTO],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.ATENDIMENTO],
  LIST: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.ATENDIMENTO],
  READ: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.ATENDIMENTO],
  UPDATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.ATENDIMENTO],
};

const TRACK_COLD = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.CORP_ADMIN,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.CORP_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.CORP_ADMIN,
  ],
};

const TRACK_HOT = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.CORP_ADMIN,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.CORP_ADMIN],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.CORP_ADMIN,
  ],
};

const TRACK_SUGGESTION = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
};

const TRACK_TRADE = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
  ],
};

const USER = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.CORP_ADMIN,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.CORP_ADMIN,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.CORP_ADMIN,
  ],
};

const VOUCHER = {
  CREATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
  DELETE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
  ],
  UPDATE: [Roles.SUPER_ADMIN, Roles.ADMIN_SKEELO, Roles.MARKETING],
};

const collectionsRoles = [
  Roles.SUPER_ADMIN,
  Roles.ADMIN_SKEELO,
  Roles.EDITORIAL_SKEELO_AVANCADO,
  Roles.EDITORIAL_SKEELO_BASICO,
  Roles.LEITURA_SKEELO,
  Roles.ATENDIMENTO,
  Roles.MARKETING,
];

const COLLECTION = {
  CREATE: [...collectionsRoles],
  LIST: [...collectionsRoles],
  READ: [...collectionsRoles],
  UPDATE: [...collectionsRoles],
  DELETE: [],
};

const COUPON = {
  CREATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
  DELETE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
  UPDATE: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
  ],
  LIST: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
  READ: [
    Roles.SUPER_ADMIN,
    Roles.ADMIN_SKEELO,
    Roles.EDITORIAL_SKEELO_AVANCADO,
    Roles.EDITORIAL_SKEELO_BASICO,
    Roles.ADMIN_EDITORA,
    Roles.EDITORA_ESCRITA,
    Roles.EDITORA_LEITURA,
    Roles.LEITURA_SKEELO,
    Roles.ATENDIMENTO,
    Roles.MARKETING,
    Roles.CORP_ADMIN,
    Roles.AUTOR,
  ],
};

export const ROLES_BY_CONTEXT_AND_PERMISSION: RolesByContextAndPermission = {
  ADMIN_PROMOTION,
  BI,
  BUNDLE,
  CAMPAIGN,
  CARRIER,
  COLLECTION,
  CLUB,
  CONTRIBUTOR,
  CORP_ADMIN,
  DISTRIBUTOR,
  DOCUMENT_FAQ,
  DOCUMENT_POLICY,
  DOCUMENT_TERM,
  DEV_TOOLS,
  IMPRINT,
  LINKED_BOOK,
  SHORTEN_URL,
  PLAN,
  PRICE_RANGE,
  PRODUCT,
  PUBLISHER,
  STORE,
  STREAMING,
  SUBSCRIBER,
  TRACK_COLD,
  TRACK_HOT,
  TRACK_SUGGESTION,
  TRACK_TRADE,
  USER,
  VOUCHER,
  COUPON,
};
