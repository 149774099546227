import { Container, Typography } from '@mui/material';
import { m } from 'framer-motion';

import { ForbiddenIllustration } from '../assets/illustrations';
import { MotionContainer, varBounce } from '../components/animate';
import { Roles } from './auth-context/enums/Roles';
import { Permission, usePermission } from './usePermission';

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: Roles[] | undefined;
  permission?: Permission;
  children: React.ReactNode;
};

export default function RolePermissionBasedGuard({
  hasContent,
  roles,
  permission,
  children,
}: RoleBasedGuardProp) {
  const { hasPermission } = usePermission(permission, roles);

  if (typeof permission !== 'undefined' && !hasPermission) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Acesso Negado
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Você não tem permissão para acessar essa página
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
