import { AUTH_CONTEXT } from '@/auth/auth-context';
import { Permissions } from '@/auth/auth-context/enums/Permissions';
import Iconify from '@/components/iconify/Iconify';
import SvgColor from '@/components/svg-color';
import { PATH_CMS } from '@/routes/paths';
import _ from 'lodash';

const icon = (icon: string) => {
  if (/:/.test(icon)) {
    return <Iconify icon={icon} />;
  }

  return (
    <SvgColor
      src={`/assets/icons/navbar/${icon}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );
};

const ICONS = {
  user: icon('ic_user'),
  product: icon('mdi:library-books'),
  bookClub: icon('mdi:library-books'),
  campaign: icon('eva:email-outline'),
  promotion: icon('mdi:account-arrow-up'),
  contributor: icon('eva:people-outline'),
  distributor: icon('eva:printer-outline'),
  imprint: icon('eva:printer-outline'),
  publisher: icon('eva:printer-outline'),
  priceRange: icon('eva:pricetags-outline'),
  plan: icon('mdi:cogs'),
  document: icon('eva:file-text-outline'),
  voucher: icon('mdi:confirmation-number-outline'),
  track: icon('mdi:book-open-outline'),
  dashboard: icon('ic_dashboard'),
  link: icon('mdi:attachment'),
  dev_tools: icon('mdi:cogs'),
};

const navSubscriber = {
  dataTestId: 'nav-subscriber-root',
  title: 'Skeelovers',
  path: PATH_CMS.subscriber.root,
  icon: ICONS.user,
  roles: _.uniq([
    ...AUTH_CONTEXT.SUBSCRIBER.roles.CREATE,
    ...AUTH_CONTEXT.SUBSCRIBER.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.SUBSCRIBER.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-subscriber-list',
      title: 'Listar',
      path: PATH_CMS.subscriber.list,
      roles: AUTH_CONTEXT.SUBSCRIBER.roles.LIST,
      permission: AUTH_CONTEXT.SUBSCRIBER.permissions.LIST,
    },
    {
      dataTestId: 'nav-subscriber-create',
      title: 'Cadastrar',
      path: PATH_CMS.subscriber.create,
      roles: AUTH_CONTEXT.SUBSCRIBER.roles.CREATE,
      permission: AUTH_CONTEXT.SUBSCRIBER.permissions.CREATE,
    },
    {
      dataTestId: 'nav-subscriber-list-cold',
      title: 'Consultar inativos',
      path: PATH_CMS.subscriber.listCold,
      roles: AUTH_CONTEXT.SUBSCRIBER.roles.LIST,
      permission: AUTH_CONTEXT.SUBSCRIBER.permissions.LIST,
    },
  ],
};

const navB2CProduct = {
  dataTestId: 'nav-b2c-product-root',
  title: 'Loja',
  path: PATH_CMS.productB2C.root,
  icon: ICONS.product,
  roles: _.uniq([
    ...AUTH_CONTEXT.STORE.roles.CREATE,
    ...AUTH_CONTEXT.STORE.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.STORE.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-b2c-product-list',
      title: 'Listar',
      path: PATH_CMS.productB2C.list,
      roles: AUTH_CONTEXT.STORE.roles.LIST,
      permission: AUTH_CONTEXT.STORE.permissions.LIST,
    },
    {
      dataTestId: 'nav-b2c-product-create',
      title: 'Cadastrar',
      path: PATH_CMS.productB2C.create,
      roles: AUTH_CONTEXT.STORE.roles.CREATE,
      permission: AUTH_CONTEXT.STORE.permissions.CREATE,
    },
    {
      dataTestId: 'nav-b2c-discount-coupons',
      title: 'Cupons de desconto',
      path: PATH_CMS.productB2C.discount_coupons,
      roles: AUTH_CONTEXT.COUPON.roles.READ,
      permission: AUTH_CONTEXT.COUPON.permissions.READ,
    },
  ],
};

const navB2BProduct = {
  dataTestId: 'nav-b2b-product-root',
  title: 'Bundle',
  path: PATH_CMS.bundle.root,
  icon: ICONS.product,
  roles: _.uniq([
    ...AUTH_CONTEXT.BUNDLE.roles.CREATE,
    ...AUTH_CONTEXT.BUNDLE.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.BUNDLE.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-b2b-product-create',
      title: 'Cadastrar',
      path: PATH_CMS.bundle.create,
      roles: AUTH_CONTEXT.BUNDLE.roles.CREATE,
      permission: AUTH_CONTEXT.BUNDLE.permissions.CREATE,
    },
    {
      dataTestId: 'nav-b2b-product-list',
      title: 'Listar',
      path: PATH_CMS.bundle.list,
      roles: AUTH_CONTEXT.BUNDLE.roles.LIST,
      permission: AUTH_CONTEXT.BUNDLE.permissions.LIST,
    },
    {
      dataTestId: 'nav-b2b-product-trails',
      title: 'Trilhas',
      path: PATH_CMS.bundle.tracks.root,
      roles: AUTH_CONTEXT.BUNDLE.roles.LIST,
      permission: AUTH_CONTEXT.BUNDLE.permissions.LIST,
      children: [
        {
          dataTestId: 'nav-b2b-product-trails-created',
          title: 'Cadastradas',
          path: PATH_CMS.bundle.tracks.hot,
          roles: AUTH_CONTEXT.BUNDLE.roles.LIST,
          permission: AUTH_CONTEXT.BUNDLE.permissions.LIST,
        },
        {
          dataTestId: 'nav-b2b-product-trails-elegible',
          title: 'Elegíveis',
          path: PATH_CMS.bundle.tracks.cold,
          roles: AUTH_CONTEXT.BUNDLE.roles.LIST,
          permission: AUTH_CONTEXT.BUNDLE.permissions.LIST,
        },
        {
          dataTestId: 'nav-b2b-product-trails-trade',
          title: 'Troca',
          path: PATH_CMS.bundle.tracks.trade,
          roles: AUTH_CONTEXT.BUNDLE.roles.LIST,
          permission: AUTH_CONTEXT.BUNDLE.permissions.LIST,
        },
      ],
    },
  ],
};

const navStreaming = {
  dataTestId: 'nav-streaming-root',
  title: 'Streaming',
  path: PATH_CMS.productStreaming.root,
  icon: ICONS.product,
  roles: _.uniq(
    _.concat(
      [
        ...AUTH_CONTEXT.STREAMING.roles.CREATE,
        ...AUTH_CONTEXT.STREAMING.roles.LIST,
      ],
      [
        ...AUTH_CONTEXT.TRACK_SUGGESTION.roles.CREATE,
        ...AUTH_CONTEXT.TRACK_SUGGESTION.roles.LIST,
      ]
    )
  ),
  permission: [
    [AUTH_CONTEXT.STREAMING.context, [Permissions.LIST, Permissions.CREATE]],
    [
      AUTH_CONTEXT.TRACK_SUGGESTION.context,
      [Permissions.LIST, Permissions.CREATE],
    ],
  ],
  children: [
    {
      dataTestId: 'nav-streaming-create',
      title: 'Cadastrar',
      path: PATH_CMS.productStreaming.create,
      roles: AUTH_CONTEXT.STREAMING.roles.CREATE,
      permission: AUTH_CONTEXT.STREAMING.permissions.CREATE,
    },
    {
      title: 'Listar',
      dataTestId: 'nav-streaming-list',
      path: PATH_CMS.productStreaming.list,
      roles: AUTH_CONTEXT.STREAMING.roles.LIST,
      permission: AUTH_CONTEXT.STREAMING.permissions.LIST,
    },
    {
      dataTestId: 'nav-streaming-trails',
      title: 'Trilhas',
      path: PATH_CMS.streaming.tracks.root,
      roles: AUTH_CONTEXT.STREAMING.roles.LIST,
      permission: AUTH_CONTEXT.STREAMING.permissions.LIST,
      children: [
        {
          dataTestId: 'nav-streaming-trails-created',
          title: 'Cadastradas',
          path: PATH_CMS.streaming.tracks.hot,
          roles: AUTH_CONTEXT.STREAMING.roles.LIST,
          permission: AUTH_CONTEXT.STREAMING.permissions.LIST,
        },
        {
          dataTestId: 'nav-streaming-trails-elegible',
          title: 'Elegíveis',
          path: PATH_CMS.streaming.tracks.cold,
          roles: AUTH_CONTEXT.STREAMING.roles.LIST,
          permission: AUTH_CONTEXT.STREAMING.permissions.LIST,
        },
        {
          dataTestId: 'nav-streaming-trails-trade',
          title: 'Troca',
          path: PATH_CMS.streaming.tracks.trade,
          roles: AUTH_CONTEXT.STREAMING.roles.LIST,
          permission: AUTH_CONTEXT.STREAMING.permissions.LIST,
        },
      ],
    },
    {
      dataTestId: 'nav-streaming-vitrine-root',
      title: 'Vitrine',
      path: PATH_CMS.productStreaming.vitrine.root,
      roles: _.uniq([
        ...AUTH_CONTEXT.TRACK_SUGGESTION.roles.CREATE,
        ...AUTH_CONTEXT.TRACK_SUGGESTION.roles.LIST,
      ]),
      permission: [
        AUTH_CONTEXT.TRACK_SUGGESTION.context,
        [Permissions.LIST, Permissions.CREATE],
      ],
      children: [
        {
          dataTestId: 'nav-streaming-vitrine-list',
          title: 'Listar',
          path: PATH_CMS.productStreaming.vitrine.list,
          roles: AUTH_CONTEXT.TRACK_SUGGESTION.roles.LIST,
          permission: AUTH_CONTEXT.TRACK_SUGGESTION.permissions.LIST,
        },
        {
          dataTestId: 'nav-streaming-vitrine-create',
          title: 'Cadastrar',
          path: PATH_CMS.productStreaming.vitrine.create,
          roles: AUTH_CONTEXT.TRACK_SUGGESTION.roles.CREATE,
          permission: AUTH_CONTEXT.TRACK_SUGGESTION.permissions.CREATE,
        },
      ],
    },
  ],
};

const navClub = {
  dataTestId: 'nav-bookclub-root',
  title: 'Clube do Livro',
  path: PATH_CMS.bookClub.root,
  icon: ICONS.bookClub,
  roles: _.uniq([
    ...AUTH_CONTEXT.CLUB.roles.CREATE,
    ...AUTH_CONTEXT.CLUB.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.CLUB.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-bookclub-list',
      title: 'Listar',
      path: PATH_CMS.bookClub.list,
      roles: AUTH_CONTEXT.CLUB.roles.LIST,
      permission: AUTH_CONTEXT.CLUB.permissions.LIST,
    },
    {
      dataTestId: 'nav-bookclub-create',
      title: 'Cadastrar',
      path: PATH_CMS.bookClub.create,
      roles: AUTH_CONTEXT.CLUB.roles.CREATE,
      permission: AUTH_CONTEXT.CLUB.permissions.CREATE,
    },
  ],
};

const navLinkedBooks = {
  dataTestId: 'nav-linkedbooks-root',
  title: 'Livros Associados',
  path: PATH_CMS.linkedBooks.root,
  icon: ICONS.bookClub,
  roles: _.uniq([
    ...AUTH_CONTEXT.LINKED_BOOK.roles.CREATE,
    ...AUTH_CONTEXT.LINKED_BOOK.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.LINKED_BOOK.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-linkedbooks-list',
      title: 'Listar',
      path: PATH_CMS.linkedBooks.list,
      roles: AUTH_CONTEXT.LINKED_BOOK.roles.LIST,
      permission: AUTH_CONTEXT.LINKED_BOOK.permissions.LIST,
    },
    {
      dataTestId: 'nav-linkedbooks-create',
      title: 'Cadastrar',
      path: PATH_CMS.linkedBooks.create,
      roles: AUTH_CONTEXT.LINKED_BOOK.roles.CREATE,
      permission: AUTH_CONTEXT.LINKED_BOOK.permissions.CREATE,
    },
  ],
};

const navCampaign = {
  dataTestId: 'nav-campaing-root',
  title: 'Gestão de campanha',
  path: PATH_CMS.campaign.root,
  icon: ICONS.campaign,
  roles: _.uniq([
    ...AUTH_CONTEXT.CAMPAIGN.roles.CREATE,
    ...AUTH_CONTEXT.CAMPAIGN.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.CAMPAIGN.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-campaing-list',
      title: 'Listar',
      path: PATH_CMS.campaign.list,
      roles: AUTH_CONTEXT.CAMPAIGN.roles.LIST,
      permission: AUTH_CONTEXT.CAMPAIGN.permissions.LIST,
    },
    {
      dataTestId: 'nav-campaing-create',
      title: 'Cadastrar',
      path: PATH_CMS.campaign.create,
      roles: AUTH_CONTEXT.CAMPAIGN.roles.CREATE,
      permission: AUTH_CONTEXT.CAMPAIGN.permissions.CREATE,
    },
  ],
};

const navBaseGenerator = {
  dataTestId: 'nav-basegenerator-root',
  title: 'Gerador de Base',
  path: PATH_CMS.baseGenerator.root,
  icon: ICONS.plan,
  roles: _.uniq([
    ...AUTH_CONTEXT.BI.roles.CREATE,
    ...AUTH_CONTEXT.BI.roles.LIST,
  ]),
  permission: [AUTH_CONTEXT.BI.context, [Permissions.LIST, Permissions.CREATE]],
  children: [
    {
      dataTestId: 'nav-basegenerator-list',
      title: 'Listar',
      path: PATH_CMS.baseGenerator.list,
      roles: AUTH_CONTEXT.BI.roles.LIST,
      permission: AUTH_CONTEXT.BI.permissions.LIST,
    },
    {
      dataTestId: 'nav-basegenerator-create',
      title: 'Cadastrar',
      path: PATH_CMS.baseGenerator.create,
      roles: AUTH_CONTEXT.BI.roles.CREATE,
      permission: AUTH_CONTEXT.BI.permissions.CREATE,
    },
  ],
};

const navPromotion = {
  dataTestId: 'nav-promotion-root',
  title: 'Administrar promoção',
  path: PATH_CMS.promotion.root,
  icon: ICONS.promotion,
  roles: AUTH_CONTEXT.ADMIN_PROMOTION.roles.CREATE,
  permission: AUTH_CONTEXT.ADMIN_PROMOTION.permissions.CREATE,
  children: [
    {
      dataTestId: 'nav-promotion-create',
      title: 'Cadastrar',
      path: PATH_CMS.promotion.create,
      roles: AUTH_CONTEXT.ADMIN_PROMOTION.roles.CREATE,
      permission: AUTH_CONTEXT.ADMIN_PROMOTION.permissions.CREATE,
    },
  ],
};

const navContributor = {
  dataTestId: 'nav-contributor-root',
  title: 'Contribuidor',
  path: PATH_CMS.contributor.root,
  icon: ICONS.contributor,
  roles: _.uniq([
    ...AUTH_CONTEXT.CONTRIBUTOR.roles.CREATE,
    ...AUTH_CONTEXT.CONTRIBUTOR.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.CONTRIBUTOR.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-contributor-list',
      title: 'Listar',
      path: PATH_CMS.contributor.list,
      roles: AUTH_CONTEXT.CONTRIBUTOR.roles.LIST,
      permission: AUTH_CONTEXT.CONTRIBUTOR.permissions.LIST,
    },
    {
      dataTestId: 'nav-contributor-create',
      title: 'Cadastrar',
      path: PATH_CMS.contributor.create,
      roles: AUTH_CONTEXT.CONTRIBUTOR.roles.CREATE,
      permission: AUTH_CONTEXT.CONTRIBUTOR.permissions.CREATE,
    },
  ],
};

const navDistributor = {
  dataTestId: 'nav-distributor-root',
  title: 'Distribuidor',
  path: PATH_CMS.distributor.root,
  icon: ICONS.distributor,
  roles: _.uniq([
    ...AUTH_CONTEXT.DISTRIBUTOR.roles.CREATE,
    ...AUTH_CONTEXT.DISTRIBUTOR.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.DISTRIBUTOR.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-distributor-list',
      title: 'Listar',
      path: PATH_CMS.distributor.list,
      roles: AUTH_CONTEXT.DISTRIBUTOR.roles.LIST,
      permission: AUTH_CONTEXT.DISTRIBUTOR.permissions.LIST,
    },
    {
      dataTestId: 'nav-distributor-create',
      title: 'Cadastrar',
      path: PATH_CMS.distributor.create,
      roles: AUTH_CONTEXT.DISTRIBUTOR.roles.CREATE,
      permission: AUTH_CONTEXT.DISTRIBUTOR.permissions.CREATE,
    },
  ],
};

const navImprint = {
  dataTestId: 'nav-imprint-root',
  title: 'Selo',
  path: PATH_CMS.imprint.root,
  icon: ICONS.imprint,
  roles: _.uniq([
    ...AUTH_CONTEXT.IMPRINT.roles.CREATE,
    ...AUTH_CONTEXT.IMPRINT.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.IMPRINT.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-imprint-list',
      title: 'Listar',
      path: PATH_CMS.imprint.list,
      roles: AUTH_CONTEXT.IMPRINT.roles.LIST,
      permission: AUTH_CONTEXT.IMPRINT.permissions.LIST,
    },
    {
      dataTestId: 'nav-imprint-create',
      title: 'Cadastrar',
      path: PATH_CMS.imprint.create,
      roles: AUTH_CONTEXT.IMPRINT.roles.CREATE,
      permission: AUTH_CONTEXT.IMPRINT.permissions.CREATE,
    },
  ],
};

const navPublisher = {
  dataTestId: 'nav-publisher-root',
  title: 'Editora',
  path: PATH_CMS.publisher.root,
  icon: ICONS.publisher,
  roles: _.uniq([
    ...AUTH_CONTEXT.PUBLISHER.roles.CREATE,
    ...AUTH_CONTEXT.PUBLISHER.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.PUBLISHER.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-publisher-list',
      title: 'Listar',
      path: PATH_CMS.publisher.list,
      roles: AUTH_CONTEXT.PUBLISHER.roles.LIST,
      permission: AUTH_CONTEXT.PUBLISHER.permissions.LIST,
    },
    {
      dataTestId: 'nav-publisher-create',
      title: 'Cadastrar',
      path: PATH_CMS.publisher.create,
      roles: AUTH_CONTEXT.PUBLISHER.roles.CREATE,
      permission: AUTH_CONTEXT.PUBLISHER.permissions.CREATE,
    },
    {
      dataTestId: 'nav-publisher-create',
      title: 'Forecast',
      path: PATH_CMS.publisher.forecast,
      roles: AUTH_CONTEXT.PUBLISHER.roles.CREATE,
      permission: AUTH_CONTEXT.PUBLISHER.permissions.CREATE,
    },
  ],
};

const navPlan = {
  title: 'Gestão de planos',
  path: PATH_CMS.plan.root,
  icon: ICONS.plan,
  roles: AUTH_CONTEXT.PLAN.roles.LIST,
  permission: AUTH_CONTEXT.PLAN.permissions.LIST,
  children: [
    {
      title: 'Cadastrar',
      path: PATH_CMS.plan.create,
      roles: AUTH_CONTEXT.PLAN.roles.LIST, // todo: criar permissao plan.CREATE na base
      permission: AUTH_CONTEXT.PLAN.permissions.LIST,
    },
    {
      title: 'Listar',
      path: PATH_CMS.plan.list,
      roles: AUTH_CONTEXT.PLAN.roles.LIST,
      permission: AUTH_CONTEXT.PLAN.permissions.LIST,
    },
  ],
};

const navPriceRange = {
  dataTestId: 'nav-pricerange-root',
  title: 'Faixa de preço',
  path: PATH_CMS.priceRange.root,
  icon: ICONS.priceRange,
  roles: _.uniq([
    ...AUTH_CONTEXT.PRICE_RANGE.roles.CREATE,
    ...AUTH_CONTEXT.PRICE_RANGE.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.PRICE_RANGE.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-pricerange-list',
      title: 'Listar',
      path: PATH_CMS.priceRange.list,
      roles: AUTH_CONTEXT.PRICE_RANGE.roles.LIST,
      permission: AUTH_CONTEXT.PRICE_RANGE.permissions.LIST,
    },
    {
      dataTestId: 'nav-pricerange-create',
      title: 'Cadastrar',
      path: PATH_CMS.priceRange.create,
      roles: AUTH_CONTEXT.PRICE_RANGE.roles.CREATE,
      permission: AUTH_CONTEXT.PRICE_RANGE.permissions.CREATE,
    },
  ],
};

const navDocument = {
  dataTestId: 'nav-document-root',
  title: 'Documentos',
  path: PATH_CMS.document.root,
  icon: ICONS.document,
  roles: _.uniq(
    _.concat(
      [
        ...AUTH_CONTEXT.DOCUMENT_FAQ.roles.CREATE,
        ...AUTH_CONTEXT.DOCUMENT_FAQ.roles.LIST,
      ],
      [
        ...AUTH_CONTEXT.DOCUMENT_POLICY.roles.CREATE,
        ...AUTH_CONTEXT.DOCUMENT_POLICY.roles.LIST,
      ],
      [
        ...AUTH_CONTEXT.DOCUMENT_TERM.roles.CREATE,
        ...AUTH_CONTEXT.PRICE_RANGE.roles.LIST,
      ]
    )
  ),
  permission: [
    [AUTH_CONTEXT.DOCUMENT_FAQ.context, [Permissions.LIST, Permissions.CREATE]],
    [
      AUTH_CONTEXT.DOCUMENT_POLICY.context,
      [Permissions.LIST, Permissions.CREATE],
    ],
    [
      AUTH_CONTEXT.DOCUMENT_TERM.context,
      [Permissions.LIST, Permissions.CREATE],
    ],
  ],
  children: [
    {
      dataTestId: 'nav-document-faq',
      title: 'Faq',
      path: PATH_CMS.document.faq.root,
      roles: _.uniq([
        ...AUTH_CONTEXT.DOCUMENT_FAQ.roles.CREATE,
        ...AUTH_CONTEXT.DOCUMENT_FAQ.roles.LIST,
      ]),
      permission: [
        AUTH_CONTEXT.DOCUMENT_FAQ.context,
        [Permissions.LIST, Permissions.CREATE],
      ],
      children: [
        {
          dataTestId: 'nav-document-faq-list',
          title: 'Listar',
          path: PATH_CMS.document.faq.list,
          roles: AUTH_CONTEXT.DOCUMENT_FAQ.roles.LIST,
          permission: AUTH_CONTEXT.DOCUMENT_FAQ.permissions.LIST,
        },
        {
          dataTestId: 'nav-document-faq-create',
          title: 'Cadastrar',
          path: PATH_CMS.document.faq.create,
          roles: AUTH_CONTEXT.DOCUMENT_FAQ.roles.CREATE,
          permission: AUTH_CONTEXT.DOCUMENT_FAQ.permissions.CREATE,
        },
      ],
    },
    {
      dataTestId: 'nav-document-policy',
      title: 'Política de privacidade',
      path: PATH_CMS.document.policy.root,
      roles: _.uniq([
        ...AUTH_CONTEXT.DOCUMENT_POLICY.roles.CREATE,
        ...AUTH_CONTEXT.DOCUMENT_POLICY.roles.LIST,
      ]),
      permission: [
        AUTH_CONTEXT.DOCUMENT_POLICY.context,
        [Permissions.LIST, Permissions.CREATE],
      ],
      children: [
        {
          dataTestId: 'nav-document-policy-list',
          title: 'Listar',
          path: PATH_CMS.document.policy.list,
          roles: AUTH_CONTEXT.DOCUMENT_POLICY.roles.LIST,
          permission: AUTH_CONTEXT.DOCUMENT_POLICY.permissions.LIST,
        },
        {
          dataTestId: 'nav-document-policy-create',
          title: 'Cadastrar',
          path: PATH_CMS.document.policy.create,
          roles: AUTH_CONTEXT.DOCUMENT_POLICY.roles.CREATE,
          permission: AUTH_CONTEXT.DOCUMENT_POLICY.permissions.CREATE,
        },
      ],
    },
    {
      dataTestId: 'nav-document-term',
      title: 'Termo de uso',
      path: PATH_CMS.document.term.root,
      roles: _.uniq([
        ...AUTH_CONTEXT.DOCUMENT_TERM.roles.CREATE,
        ...AUTH_CONTEXT.DOCUMENT_TERM.roles.LIST,
      ]),
      permission: [
        AUTH_CONTEXT.DOCUMENT_TERM.context,
        [Permissions.LIST, Permissions.CREATE],
      ],
      children: [
        {
          dataTestId: 'nav-document-term-list',
          title: 'Listar',
          path: PATH_CMS.document.term.list,
          roles: AUTH_CONTEXT.DOCUMENT_TERM.roles.LIST,
          permission: AUTH_CONTEXT.DOCUMENT_TERM.permissions.LIST,
        },
        {
          dataTestId: 'nav-document-term-create',
          title: 'Cadastrar',
          path: PATH_CMS.document.term.create,
          roles: AUTH_CONTEXT.DOCUMENT_TERM.roles.CREATE,
          permission: AUTH_CONTEXT.DOCUMENT_TERM.permissions.CREATE,
        },
      ],
    },
  ],
};

const navVoucher = {
  dataTestId: 'nav-voucher-root',
  title: 'Voucher',
  path: PATH_CMS.voucher.root,
  icon: ICONS.voucher,
  roles: _.uniq([
    ...AUTH_CONTEXT.VOUCHER.roles.CREATE,
    ...AUTH_CONTEXT.VOUCHER.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.VOUCHER.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-voucher-list',
      title: 'Listar',
      path: PATH_CMS.voucher.list,
      roles: AUTH_CONTEXT.VOUCHER.roles.LIST,
      permission: AUTH_CONTEXT.VOUCHER.permissions.LIST,
    },
    {
      dataTestId: 'nav-voucher-create',
      title: 'Cadastrar',
      path: PATH_CMS.voucher.create,
      roles: AUTH_CONTEXT.VOUCHER.roles.CREATE,
      permission: AUTH_CONTEXT.VOUCHER.permissions.CREATE,
    },
  ],
};

const navCorp = {
  dataTestId: 'nav-corp-root',
  title: 'Início',
  path: PATH_CMS.app,
  icon: ICONS.dashboard,
  roles: AUTH_CONTEXT.CORP_ADMIN.roles.READ,
  permission: AUTH_CONTEXT.CORP_ADMIN.permissions.READ,
};

const navUser = {
  dataTestId: 'nav-user-root',
  title: 'Colaboradores',
  path: PATH_CMS.user.root,
  icon: ICONS.user,
  roles: _.uniq([
    ...AUTH_CONTEXT.USER.roles.CREATE,
    ...AUTH_CONTEXT.USER.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.USER.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-user-list',
      title: 'Listar',
      path: PATH_CMS.user.list,
      roles: AUTH_CONTEXT.USER.roles.LIST,
      permission: AUTH_CONTEXT.USER.permissions.LIST,
    },
    {
      dataTestId: 'nav-user-create',
      title: 'Cadastrar',
      path: PATH_CMS.user.create,
      roles: AUTH_CONTEXT.USER.roles.CREATE,
      permission: AUTH_CONTEXT.USER.permissions.CREATE,
    },
    {
      dataTestId: 'nav-user-import',
      title: 'Importar',
      path: PATH_CMS.user.import,
      roles: AUTH_CONTEXT.USER.roles.CREATE,
      permission: AUTH_CONTEXT.USER.permissions.CREATE,
    },
  ],
};

const navCarrier = {
  dataTestId: 'nav-carrier-root',
  title: 'Operadoras',
  path: PATH_CMS.carrier.root,
  icon: ICONS.plan,
  roles: _.uniq([
    ...AUTH_CONTEXT.CARRIER.roles.CREATE,
    ...AUTH_CONTEXT.CARRIER.roles.LIST,
  ]),
  permission: [
    AUTH_CONTEXT.CARRIER.context,
    [Permissions.LIST, Permissions.CREATE],
  ],
  children: [
    {
      dataTestId: 'nav-carrier-list',
      title: 'Listar',
      path: PATH_CMS.carrier.list,
      roles: AUTH_CONTEXT.USER.roles.LIST,
      permission: AUTH_CONTEXT.CARRIER.permissions.LIST,
    },
    {
      dataTestId: 'nav-carrier-create',
      title: 'Cadastrar',
      path: PATH_CMS.carrier.create,
      roles: AUTH_CONTEXT.USER.roles.CREATE,
      permission: AUTH_CONTEXT.CARRIER.permissions.CREATE,
    },
  ],
};

const navLinkShortner = {
  dataTestId: 'nav-linkshortner-root',
  title: 'Encurtador de links',
  path: PATH_CMS.carrier.root,
  icon: ICONS.link,
  roles: _.uniq([...AUTH_CONTEXT.SHORTEN_URL.roles.CREATE]),
  permission: [AUTH_CONTEXT.SHORTEN_URL.context, [Permissions.CREATE]],
  children: [
    {
      dataTestId: 'nav-linkshortner-create',
      title: 'Cadastrar',
      path: PATH_CMS.linkShortner.create,
      roles: AUTH_CONTEXT.SHORTEN_URL.roles.CREATE,
      permission: AUTH_CONTEXT.SHORTEN_URL.permissions.CREATE,
    },
  ],
};

const navDevTools = {
  dataTestId: 'nav-devtools-root',
  title: 'Ferramentas dev',
  path: PATH_CMS.dev_tools.root,
  icon: ICONS.dev_tools,
  roles: _.uniq([...AUTH_CONTEXT.DEV_TOOLS.roles.CREATE]),
  permission: [AUTH_CONTEXT.DEV_TOOLS.context, [Permissions.CREATE]],
  children: [
    {
      dataTestId: 'nav-devtools-flag',
      title: 'Feature Flag',
      path: PATH_CMS.dev_tools.feature_flag.root,
      roles: AUTH_CONTEXT.DEV_TOOLS.roles.CREATE,
      permission: AUTH_CONTEXT.DEV_TOOLS.permissions.CREATE,
    },
  ],
};

const navCollections = {
  dataTestId: 'nav-collections-root',
  title: 'Coleções',
  path: PATH_CMS.collections.root,
  icon: ICONS.product,
  roles: _.uniq([...AUTH_CONTEXT.COLLECTION.roles.CREATE]),
  permission: [AUTH_CONTEXT.COLLECTION.context, [Permissions.CREATE]],
  children: [
    {
      dataTestId: 'nav-collections-list',
      title: 'Listar',
      path: PATH_CMS.collections.list,
      roles: AUTH_CONTEXT.COLLECTION.roles.LIST,
      permission: AUTH_CONTEXT.COLLECTION.permissions.LIST,
    },
    {
      dataTestId: 'nav-collections-create',
      title: 'Cadastrar',
      path: PATH_CMS.collections.create,
      roles: AUTH_CONTEXT.COLLECTION.roles.CREATE,
      permission: AUTH_CONTEXT.COLLECTION.permissions.CREATE,
    },
  ],
};

const navConfig = [
  {
    subheader: '',
    items: [
      navCorp,
      navSubscriber,
      navUser,
      navB2CProduct,
      navCollections,
      navB2BProduct,
      navStreaming,
      navLinkedBooks,
      navClub,
      navCampaign,
      navBaseGenerator,
      navPromotion,
      navContributor,
      navDistributor,
      navImprint,
      navPublisher,
      navCarrier,
      navPlan,
      navPriceRange,
      navDocument,
      navVoucher,
      navLinkShortner,
      navDevTools,
    ],
  },
];

export default navConfig;
