import { useAuthContext } from '@/auth/useAuthContext';
import _ from 'lodash';

import { Contexts } from './auth-context/enums/Contexts';
import { Roles } from './auth-context/enums/Roles';

type Permissions = 'create' | 'update' | 'read' | 'delete' | 'list';

export type Permission =
  | Contexts
  | [Contexts, Permissions | Permissions[]]
  | [Contexts, Permissions | Permissions[]][]
  | undefined;

type UsePermissionReturn = {
  hasCreatePermission?: boolean;
  hasDeletePermission?: boolean;
  hasListPermission?: boolean;
  hasReadPermission?: boolean;
  hasUpdatePermission?: boolean;
  hasPermission?: boolean;
};

function userHasPermission(userPermissions: any) {
  return (permission: [Contexts, Permissions]): boolean => {
    if (!permission) {
      return true;
    }

    const [ctx, perms] = permission;

    const userPerms = _.get(userPermissions, ctx);

    if (!userPerms) {
      return false;
    }

    if (!perms) {
      return !!userPerms;
    }

    if (Array.isArray(perms)) {
      const hasSomeRome = perms.map(perm =>
        userPerms.some((userPerm: string) => userPerm === perm)
      );
      return hasSomeRome.some(perm => !!perm);
    }

    if (Array.isArray(userPerms)) {
      return userPerms?.includes(perms);
    }

    return false;
  };
}

function userHasRole(userRole: any, roles: Roles[] | undefined): boolean {
  if (!roles || roles?.length === 0) {
    return true;
  }

  return roles.includes(userRole);
}

export const usePermission = (
  permission: Permission,
  roles?: Roles[] | undefined
): UsePermissionReturn => {
  const { user } = useAuthContext();

  const checkByContext = typeof permission === 'string';

  const hasPermission = userHasPermission(user?.permissions);

  const hasRole = userHasRole(user?.role, roles);

  const hasPermissions = (perm: any[]): boolean => {
    if (!permission) {
      return true;
    }

    const first = _.first(perm);
    const last = _.last(perm);

    if (_.isArray(first)) {
      return perm.some((p: any[]) => hasPermissions(p));
    }

    return hasPermission([first, last]);
  };

  if (checkByContext) {
    return {
      hasCreatePermission: hasPermission([permission, 'create']) && hasRole,
      hasDeletePermission: hasPermission([permission, 'delete']) && hasRole,
      hasListPermission: hasPermission([permission, 'list']) && hasRole,
      hasReadPermission: hasPermission([permission, 'read']) && hasRole,
      hasUpdatePermission: hasPermission([permission, 'update']) && hasRole,
    };
  }

  return { hasPermission: hasPermissions(permission as any[]) && hasRole };
};
