export type OptionsPopover = {
  label: string;
  linkTo: string;
  showButton: boolean;
};

export enum OptionsLabelEnum {
  START = 'Início',
  PROFILE = 'Meu perfil',
  SKEELO_BUSINESS = 'Ir para Skeelo Business',
}
