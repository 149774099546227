import RolePermissionBasedGuard from '@/auth/RolePermissionBasedGuard';
import { List, Stack } from '@mui/material';

import { useLocales } from '../../../locales';
import { NavSectionProps } from '../types';
import NavList from './NavList';
import { StyledSubheader } from './styles';

export default function NavSectionVertical({
  data,
  sx,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {data.map(group => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2, pb: 4 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>
                {translate(group.subheader)}
              </StyledSubheader>
            )}

            {group.items.map(list => (
              <RolePermissionBasedGuard
                key={list.title + list.path}
                roles={list.roles}
                permission={list.permission}
              >
                <NavList data={list} depth={1} hasChild={!!list.children} />
              </RolePermissionBasedGuard>
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
