export enum Contexts {
  ADMIN_PROMOTION = 'admin_promotion',
  BI = 'bi',
  BUNDLE = 'product',
  CAMPAIGN = 'campaign',
  COLLECTION = 'collection',
  CARRIER = 'carrier',
  CLUB = 'club',
  CONTRIBUTOR = 'contributor',
  CORP_ADMIN = 'corp',
  DISTRIBUTOR = 'distributor',
  DOCUMENT_FAQ = 'document.faq',
  DOCUMENT_POLICY = 'document.policy',
  DOCUMENT_TERM = 'document.term',
  DEV_TOOLS = 'dev_tools',
  IMPRINT = 'imprint',
  LINKED_BOOK = 'linked_book',
  SHORTEN_URL = 'shorten_url',
  PLAN = 'plan',
  PRICE_RANGE = 'price_range',
  PRODUCT = 'product',
  PUBLISHER = 'publisher',
  STORE = 'product',
  STREAMING = 'product',
  SUBSCRIBER = 'subscriber',
  TRACK_COLD = 'track.cold',
  TRACK_HOT = 'track.hot',
  TRACK_SUGGESTION = 'track.suggestion',
  TRACK_TRADE = 'track.trade',
  USER = 'user',
  VOUCHER = 'voucher',
  COUPON = 'coupon',
}
